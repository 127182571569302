.scores {
  font-size: 250%;
}

.game-card h1,
.game-card h2,
.game-card h3 {
  padding: 0;
  margin: 0;
}

.level-card {
  width: 100%;
  max-width: 500px;
  margin-right: 15px;
}

.score-cards {
  display: flex;
}

.score-type-card {
  margin-top: 15px;
}

.score-type-card h3 {
  margin-bottom: 10px;
}

.score-card {
  padding: 25px 55px 55px 55px;
  min-height: 200px;
  margin: 0px;
  position: relative;
  border: 1px dashed var(--brand-main);
}

.score-card h3 {
  margin: 0;
  margin-bottom: 15px;
}
