.game-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 15px;
    justify-content: center;
}

.game-row-cell {
    display: flex;
    flex-direction: column;
    min-height: 400px;
}

.cell-span-1 {
    grid-column: span 1;
}

.cell-span-2 {
    grid-column: span 2;
}

.game-row-cell img {
    max-width: 400px;
    width: 100%;
}

.arcade-machine_image {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.game-row-cell_score-type {
    display: flex;
}

.game-row-cell_score-type_score {
    margin-left: 15px;
    flex-grow: 1;
}

@media screen and (max-width: 580px) {
  .cell-span-2 {
      grid-column: span 1;
  }

  .game-row-cell_score-type {
      flex-direction: column;
  }
}