form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
