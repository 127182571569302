* {
  box-sizing: border-box;
  --background-color: #202124;
  --foreground-color: #e8eaed;
  --brand-main: #ff03ee;
  --brand-dark: #9c0091;
  --approve-color: #0c7e0d;
  --approve-light: #0ea70f;
  --reject-color: #9c0000;
  --reject-light: #cf0808;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
  color: var(--foreground-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input, select, button {
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid var(--brand-main);
}

button {
  background-color: var(--brand-dark);
  color: var(--foreground-color);
  border: none;
}

button:hover {
  background-color: var(--brand-main);
  cursor: pointer;
}

body, input, select, button {
  font-size: 16px;
}

main {
  padding: 0px 30px;
}

.nav-header {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.nav-header .nav-links {
  margin-left: 20px;
}

.nav-header .nav-links a {
  margin-right: 10px;
}

.nav-header .nav-links a:active,
.nav-header .nav-links a:visited {
  color: lightblue;
}

.nav-header .nav-links a.active {
  color: var(--brand-main);
}

.nav-header .nav-button {
  margin-left: auto;
}

@media screen and (max-width: 580px) {
  main {
    padding: 0px 15px;
  }
  
  .nav-header {
    flex-direction: column;
  }
}
