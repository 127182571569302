.score-table {
  width: 100%;
}

.score-table th {
  text-align: start;
  border-bottom: 1px solid var(--foreground-color);
}

.score-table th,
.score-table td {
  padding: 0 10px;
}

.score-table td:first-child,
.score-table th:first-child {
  border-right: 1px solid var(--foreground-color);
}