.admin-table {
    width: 100%;
}

.admin-table th {
  text-align: start;
  border-bottom: 1px solid var(--foreground-color);
}

.admin-table th,
.admin-table td {
  padding: 0 10px;
}

.admin-table td:first-child,
.admin-table th:first-child {
  border-right: 1px solid var(--foreground-color);
}

.admin-table button {
    margin-right: 10px;
    padding: 5px;
}

.admin-table button.approve {
    background-color: var(--approve-color);
}

.admin-table button.approve:hover {
    background-color: var(--approve-light);
}

.admin-table button.reject {
    background-color: var(--reject-color);
}

.admin-table button.reject:hover {
    background-color: var(--reject-light);
}

dialog + .backdrop {
    background: rgba(0,0,0,0.5);
}